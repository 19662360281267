import { ReactNode } from "react";

import { Alert, AlertTitle, Box, SxProps } from "@mui/material";

import { traduzMensagem } from "../../state/conversions";

interface Props {
  title?: ReactNode;
  error: Error | string;
  sx?: SxProps;
}

export default function Erro({ title, error, sx }: Props) {
  return (
    <Box sx={sx}>
      <Alert severity="error">
        <AlertTitle>{title || "Erro"}</AlertTitle>
        {traduzMensagem(error)}
      </Alert>
    </Box>
  );
}
