import React, { Component, ErrorInfo, PropsWithChildren } from "react";

import { SxProps } from "@mui/material";

import Erro from "./Erro";

interface Props {
  sx?: SxProps;
}

export default class ErrorBoundary extends Component<PropsWithChildren<Props>> {
  state: { error?: Error } = {};

  static getDerivedStateFromError(error: Error) {
    return { error };
  }

  // eslint-disable-next-line class-methods-use-this
  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // eslint-disable-next-line no-console
    console.error(error, errorInfo);
  }

  render() {
    if (!this.state.error) return this.props.children;

    return <Erro sx={this.props.sx} error={this.state.error} />;
  }
}
