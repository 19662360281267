import type { AppProps } from "next/app";
import Head from "next/head";
import * as React from "react";
import { Suspense, useCallback } from "react";

import { CircularProgress } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";

import { EmotionCache } from "@emotion/cache";
import { CacheProvider } from "@emotion/react";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { useAtomValue } from "jotai";
import { SnackbarProvider } from "notistack";
import { IntlProvider } from "react-intl";
import { SWRConfig } from "swr";

import ErrorBoundary from "../components/common/ErrorBoundary";
import { apiAtom } from "../state/auth";
import { googleAuthClientId } from "../state/env";
import { createEmotionCache, theme } from "../styles/theme";

import "../styles/globals.css";

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache();

interface CustomAppProps extends AppProps {
  emotionCache: EmotionCache;
}

export default function MyApp({ Component, emotionCache = clientSideEmotionCache, pageProps }: CustomAppProps) {
  const api = useAtomValue(apiAtom);
  const fetcher = useCallback((url: string) => api(url).then((res) => res.data), [api]);

  return (
    <CacheProvider value={emotionCache}>
      <Head>
        <meta name="viewport" content="initial-scale=1, width=device-width" />
      </Head>
      <ThemeProvider theme={theme}>
        {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
        <CssBaseline />
        <GoogleOAuthProvider clientId={googleAuthClientId}>
          <SWRConfig value={{ fetcher }}>
            <SnackbarProvider maxSnack={3} variant="success">
              <ErrorBoundary>
                <Suspense fallback={<CircularProgress />}>
                  <IntlProvider locale="pt-BR" defaultLocale="pt-BR">
                    <Component {...pageProps} />
                  </IntlProvider>
                </Suspense>
              </ErrorBoundary>
            </SnackbarProvider>
          </SWRConfig>
        </GoogleOAuthProvider>
      </ThemeProvider>
    </CacheProvider>
  );
}
