import axios from "axios";
import { atom } from "jotai";
import { atomWithStorage } from "jotai/utils";
import { jwtDecode } from "jwt-decode";

import { googleAuthClientId } from "./env";

export const credentialsAtom = atomWithStorage<string | undefined>("g-auth", undefined);

const ticker = atom(Date.now());
ticker.onMount = (setAtom) => {
  const inc = () => setAtom(Date.now());

  const interval = setInterval(inc, 5000);

  return () => clearInterval(interval);
};

export const validCredentialsAtom = atom((get) => {
  const decoded = get(decodedCredentialsAtom);
  if (!decoded) return undefined;

  if (get(ticker) >= decoded.exp * 1000) {
    console.warn("Credenciais expiradas");
    return undefined;
  }

  return decoded;
});

const decodedCredentialsAtom = atom((get) => {
  try {
    const credentials = get(credentialsAtom);
    if (!credentials) return undefined;

    const decoded = jwtDecode(credentials) as DecodedGoogleToken;

    if (decoded.aud !== googleAuthClientId || decoded.iss !== "https://accounts.google.com") {
      console.warn("Credenciais não emitidas pelo Google para nosso app", decoded);
      return undefined;
    }

    return decoded;
  } catch (e) {
    if (!(e instanceof Promise)) console.warn("Erro ao decodificar token", e);
    return undefined;
  }
});

export const apiAtom = atom((get) => {
  const creds = get(credentialsAtom);
  return axios.create({ headers: !creds ? {} : { Authorization: `Bearer ${creds}` } });
});

export interface DecodedGoogleToken {
  aud: string;
  azp: string;
  email: string;
  email_verified: boolean;
  exp: number;
  family_name: string;
  given_name: string;
  hd: string;
  iat: number;
  iss: string;
  jti: string;
  name: string;
  nbf: number;
  picture: string;
  sub: string;
}
