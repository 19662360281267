import { createTheme } from "@mui/material/styles";
import { PaletteColorOptions } from "@mui/material/styles/createPalette";

import createCache from "@emotion/cache";
import type {} from "@mui/lab/themeAugmentation";

import * as colors from "./colors";

export const gridSpacing = 3;
export const borderRadius = 12;
export const drawerWidth = 260;
export const appDrawerWidth = 320;

// Create a theme instance.
export const theme = createTheme({
  direction: "ltr",
  mixins: {
    toolbar: {
      minHeight: "48px",
      padding: "16px",
      "@media (min-width: 600px)": {
        minHeight: "48px",
      },
    },
  },
  palette: {
    primary: {
      light: "#e3f2fd",
      main: "#2196f3",
      dark: "#1e88e5",
      200: "#90caf9",
      800: "#1565c0",
    },
    secondary: {
      light: "#ede7f6",
      main: "#673ab7",
      dark: "#5e35b1",
      200: "#b39ddb",
      800: "#4527a0",
    },
    error: {
      light: "#ef9a9a",
      main: "#f44336",
      dark: "#c62828",
    },
    orange: {
      light: "#fbe9e7",
      main: "#ffab91",
      dark: "#d84315",
    },
    warning: {
      light: "#fff8e1",
      main: "#ffe57f",
      dark: "#ffc107",
    },
    success: {
      light: "#b9f6ca",
      200: "#69f0ae",
      main: "#00e676",
      dark: "#00c853",
    },
    gray: {
      50: "#fafafa",
      100: "#f5f5f5",
      200: "#eeeeee",
      300: "#e0e0e0",
      500: "#9e9e9e",
      600: "#757575",
      700: "#616161",
      900: "#212121",
    },
  },
  typography: {
    h6: { fontWeight: 500, color: colors.grey900, fontSize: "0.75rem" },
    h5: { fontSize: "0.875rem", color: colors.grey900, fontWeight: 500 },
    h4: { fontSize: "1rem", color: colors.grey900, fontWeight: 600 },
    h3: { fontSize: "1.25rem", color: colors.grey900, fontWeight: 600 },
    h2: { fontSize: "1.5rem", color: colors.grey900, fontWeight: 700 },
    h1: { fontSize: "2.125rem", color: colors.grey900, fontWeight: 700 },
    subtitle1: { fontSize: "0.875rem", fontWeight: 500, color: colors.grey900 },
    subtitle2: { fontSize: "0.75rem", fontWeight: 400, color: colors.grey500 },
    caption: { fontSize: "0.75rem", color: colors.grey500, fontWeight: 400 },
    body1: { fontSize: "0.875rem", fontWeight: 400, lineHeight: "1.334em" },
    body2: { letterSpacing: "0em", fontWeight: 400, lineHeight: "1.5em", color: colors.grey700 },
    button: { textTransform: "capitalize" },
  },
  components: {
    MuiLoadingButton: {
      defaultProps: {
        variant: "contained",
      },
    },
    MuiButton: {
      defaultProps: {
        variant: "contained",
      },
      styleOverrides: {
        root: {
          fontWeight: 500,
          borderRadius: "4px",
        },
      },
    },
    MuiPaper: {
      defaultProps: {
        elevation: 0,
      },
      styleOverrides: {
        root: {
          backgroundImage: "none",
        },
        rounded: {
          borderRadius: `${borderRadius}px`,
        },
      },
    },
    MuiCardHeader: {
      styleOverrides: {
        root: {
          color: colors.grey900,
          padding: "24px",
        },
        title: {
          fontSize: "1.125rem",
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: "24px",
        },
      },
    },
    MuiCardActions: {
      styleOverrides: {
        root: {
          padding: "24px",
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          color: colors.grey700,
          paddingTop: "10px",
          paddingBottom: "10px",
          "&.Mui-selected": {
            color: colors.secondaryDark,
            backgroundColor: colors.secondaryLight,
            "&:hover": {
              backgroundColor: colors.secondaryLight,
            },
            "& .MuiListItemIcon-root": {
              color: colors.secondaryDark,
            },
          },
          "&:hover": {
            backgroundColor: colors.secondaryLight,
            color: colors.secondaryDark,
            "& .MuiListItemIcon-root": {
              color: colors.secondaryDark,
            },
          },
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          color: colors.grey700,
          minWidth: "36px",
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        primary: {
          color: colors.grey900,
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          color: colors.grey900,
          "&::placeholder": {
            color: colors.grey500,
            fontSize: "0.875rem",
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          background: colors.codemirrorThemeBackground,
          borderRadius: `${borderRadius}px`,
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: colors.grey400,
          },
          "&:hover $notchedOutline": {
            borderColor: colors.primaryLight,
          },
          "&.MuiInputBase-multiline": {
            padding: 1,
          },
        },
        input: {
          fontWeight: 500,
          background: colors.codemirrorThemeBackground,
          padding: "15.5px 14px",
          borderRadius: `${borderRadius}px`,
          "&.Mui-readOnly": { color: "gray" },
          "&.MuiInputBase-inputSizeSmall": {
            padding: "10px 14px",
            "&.MuiInputBase-inputAdornedStart": {
              paddingLeft: 0,
            },
          },
        },
        inputAdornedStart: {
          paddingLeft: 4,
        },
        notchedOutline: {
          borderRadius: `${borderRadius}px`,
        },
      },
    },
    MuiSlider: {
      styleOverrides: {
        root: {
          "&.Mui-disabled": {
            color: colors.grey300,
          },
        },
        mark: {
          backgroundColor: colors.paper,
          width: "4px",
        },
        valueLabel: {
          color: colors.primaryLight,
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        fullWidth: true,
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: colors.grey200,
          opacity: 1,
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          color: colors.primaryDark,
          background: colors.primary200,
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          "&.MuiChip-deletable .MuiChip-deleteIcon": {
            color: "inherit",
          },
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          color: colors.paper,
          background: colors.grey700,
        },
      },
    },
  },
});

declare module "@mui/material/styles" {
  interface PaletteOptions {
    orange: PaletteColorOptions;
    gray: PaletteColorOptions;
  }
}

const isBrowser = typeof document !== "undefined";

// On the client side, Create a meta tag at the top of the <head> and set it as insertionPoint.
// This assures that MUI styles are loaded first.
// It allows developers to easily override MUI styles with other styling solutions, like CSS modules.
export function createEmotionCache() {
  let insertionPoint: HTMLElement | undefined;

  if (isBrowser) {
    const emotionInsertionPoint = document.querySelector('meta[name="emotion-insertion-point"]');
    if (emotionInsertionPoint instanceof HTMLElement) insertionPoint = emotionInsertionPoint;
  }

  return createCache({ key: "mui-style", insertionPoint });
}
