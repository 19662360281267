import { defaultSettingsMaterialLight } from "@uiw/codemirror-theme-material";

export const codemirrorThemeBackground = defaultSettingsMaterialLight.background;

export const paper = "$ffffff";
export const primaryLight = "#e3f2fd";
export const primaryDark = "#1e88e5";
export const primary200 = "#90caf9";
export const primary800 = "#1565c0";
export const orangeLight = "#fbe9e7";
export const orangeDark = "#d84315";
export const darkTextSecondary = "#8492c4";
export const grey50 = "#fafafa";
export const grey100 = "#f5f5f5";
export const grey200 = "#eeeeee";
export const grey300 = "#e0e0e0";
export const grey400 = "#cecece";
export const grey500 = "#9e9e9e";
export const grey700 = "#616161";
export const grey900 = "#212121";
export const secondaryDark = "#5e35b1";
export const secondaryLight = "#ede7f6";
