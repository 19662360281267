import { isAxiosError } from "axios";

export function objectToFieldArray(obj: Record<string, unknown>) {
  return [...Object.entries(obj ?? {}).map(([nome, valor]) => ({ nome, valor: String(valor) })), { nome: "", valor: "" }];
}

export function fieldArrayToObject(fieldArray: { nome: string; valor: string }[]): Record<string, string> {
  return fieldArray.filter(({ nome, valor }) => Boolean(nome && valor)).reduce((r, o) => ({ ...r, [o.nome]: o.valor }), {});
}

export function traduzMensagem(error: unknown) {
  if (typeof error === "string") return error;

  if (isAxiosError(error) && error.response) {
    if (possuiCampoString(error.response.data, "detail")) return error.response.data.detail;

    if (error.response?.status === 404) return "Informações não encontradas.";
    if (error.response?.status === 500) return "Erro ao comunicar com a API.";
  }

  return possuiCampoString(error, "message") ? error.message : String(error);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function possuiCampoString<P extends string>(e: any, prop: P): e is { [k in P]: string } {
  return Boolean(e && typeof e === "object" && prop in e && typeof e[prop] === "string");
}
